import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import firebase from '../../../firebase'

import { getUserSites, deleteSite } from '../../../actions/sitesWidgets'

class SitesListWidget extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.getUserSites()
        }, 1000)
    }

    render() {
        return this.props.sitesWidget.userSites.map((site, i) => {
            return (
                <div
                    onDrop={(event) => {

                        var pageId = event
                        .dataTransfer
                        .getData("pageId");

                        if(pageId){
                            console.log('onpahe drop',pageId)
                        }

                        event.dataTransfer.setData("pageId", null);
                    }}

                    className="card p-3 m-3" key={i}>
                    <p>{site.name}</p>
                    <div>
                        <button className='m-2 btn'>edit</button>
                        <button onClick={() => { this.props.deleteSite(site.id) }} className='m-2 btn btn-warning'>delete</button>
                    </div>
                </div>
            )
        })
    }
}

const mapStateToProps = ({ sitesWidget }, ownProps) => ({
    sitesWidget
})

export default connect(mapStateToProps, {
    getUserSites,
    deleteSite
})(SitesListWidget)


