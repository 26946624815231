import SitesListWidget from './index';
import { AddSiteButton } from "./addSiteButton";
import { SiteWidgetInput } from "./siteWidgetInput";
import { UserPagesInput } from "./userPagesInput";

import UserPages from "./userPages";


//
import React, { Component } from 'react';


const sitesWidgetShayderComponentKeys = {
    '1d246272-eb40-42d2-a0c8-339e47aa5ae9': {
        domId: "1d246272-eb40-42d2-a0c8-339e47aa5ae9",
        name: 'user sites',
        node: <SitesListWidget />,
    },
    'c7ffe4da-1685-4499-8d6f-5df4e1a32575': {
        domId: "c7ffe4da-1685-4499-8d6f-5df4e1a32575",
        name: 'add site button',
        node: <AddSiteButton />,
    },
    'a71b98fa-98f2-46de-a1f7-5303cb2cbcca': {
        domId: "a71b98fa-98f2-46de-a1f7-5303cb2cbcca",
        name: 'add site input',
        node: <SiteWidgetInput />,
    },
    'aa5a01e8-2257-4ada-bc91-4321ed3768fe': {
        domId: "aa5a01e8-2257-4ada-bc91-4321ed3768fe",
        name: 'user pages',
        node: <UserPages />,
    },
    'dd737c61-aaed-43ad-8317-69d271d4d4fa': {
        domId: "add737c61-aaed-43ad-8317-69d271d4d4fa",
        name: 'UserPagesInput',
        node: <UserPagesInput />,
    }
}

export default sitesWidgetShayderComponentKeys;