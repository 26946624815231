import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import rp from 'request-promise';

import ShayderPac from 'shayder-pac';

import firebase from '../../firebase';
const shayderStripAuthUrl = "https://us-central1-shayder-space.cloudfunctions.net/shayderStripeAuth";
//const shayderStripAuthUrl = "http://localhost:8080";

class ShayderProductPage extends Component {
  constructor() {
    super();
    this.state = {
      context: {

      }
    }
  }

  componentDidMount = async() => {

    const { hash } = window.location;
    if (hash.indexOf('userId') > -1 && hash.indexOf('itemId') > -1 ) {
      const query = hash.split('?')[1]

      const list = query
        .replace('#/', '')
        .split('&');

      const params = list
        .filter(sec => sec.indexOf('='))
        .map(sec => sec.split('='))
        .reduce((params, [key, value]) => ({
          ...params,
          [key]: value
        }), {});

      const { itemId, userId } = params;

      try {
          var options = {
              method: 'GET',
              uri: `${shayderStripAuthUrl}/product/${userId}/${itemId}`,
          };
          const response = await rp(options);
          const productItem = JSON.parse(response);
          if (productItem) {
              this.setState({ 
                showTime: true, context: {
                  product: {
                    ...productItem,
                  }
                }
              
              });
          }
      } catch (error) {
          console.log("TCL: componentDidMount -> error", error)
      }

    }

  }


  render() {

    return (
      <React.Fragment>
        {this.state.showTime && <ShayderPac {...{
          firebase,
          shayderMode: {
            pageId: '1b4ac938-c227-4242-ad29-746d87c16a12',
            type: 'live',
            setLocal: false,
            context: {
              ...this.state.context,
            }
          }
        }} />}

      </React.Fragment >

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  locationUtil: state.locationUtil
})

export default connect(mapStateToProps, {

})(ShayderProductPage)