import React, { Component } from 'react';
import { connect } from 'react-redux'

import firebase from '../../../firebase';
import SuccesSnackbar from './SuccesSnackBar';


class ShayderSignUp extends Component {
    constructor() {
        super();
        this.state = {
            openSnack: false,
            snackMessage: ''
        }
    }

    handleSubmit(event) {
        // const { username, password } = event.target;
        // console.log("TCL: ShayderSignUp -> handleSubmit -> {username,password}", username.value, password.value)
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {

            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            this.setState({
                openSnack: true,
                snackMessage: 'logged In'
            })




            
            // ...
        }).catch((error) => {
            console.log("TCL: ShayderSignUp -> handleSubmit -> error", error)
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            this.setState({
                openSnack: true,
                snackMessage: error.message,
            })
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
        });

    }

    render() {
        return (
            <div
                className="flex-1 p-3 mx-3 d-flex flex-column justify-content-center align-items-center">
                <SuccesSnackbar {...{ message: this.state.snackMessage, open: this.state.openSnack, handleClose: () => this.setState({ openSnack: false }) }} />
                <button onClick={() => this.handleSubmit()} className='btn btn-info m-2' >Sign In With Google</button  >
            </div>
        )
    }
}

const mapStateToProps = ({ sitesWidget }) => ({
    sitesWidget
})

export default connect(mapStateToProps, {

})(ShayderSignUp)


