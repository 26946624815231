import React, { Component } from 'react';

import  UserImages from './userImages'

import {UserPagesInput} from './userImagesInput'

const shayderImagesComponentKeys = {
    '97557513-8e7d-4f66-9eba-6aab24d12cf3': {
        domId: "97557513-8e7d-4f66-9eba-6aab24d12cf3",
        name: 'UserImages',
        node: <UserImages />,
    },
    'cb19c431-7001-4ee6-8073-fad16b99c5bb': {
        domId: "cb19c431-7001-4ee6-8073-fad16b99c5bb",
        name: 'UserPagesInput',
        node: <UserPagesInput />,
    },

}

export default shayderImagesComponentKeys;