import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import { store } from './Store';
import App from './Components/App/';
import { HashRouter } from 'react-router-dom'
const Root = ({ store, history }) => (
  <Provider store={store}>
    <App />
  </Provider>
)

render(<Root store={store} />, document.getElementById('root'));