const sitesWidget = (state = {
    inputPlaceholder: 'search / new site name',
    inputValue: '',
    userImagesSearchValue: '',
    userSites: [],
    userPages: [],
    userImages:[],
}, action) => {
    switch (action.type) {
        case 'setUserSites': {
            const { userSites } = action;
            return {
                ...state,
                userSites,
            }
        }
        case 'setUserImages': {
            const { userImages } = action;
            return {
                ...state,
                userImages,
            }
        }
        
        case 'setUserPages': {
            const { userPages } = action;
            return {
                ...state,
                userPages,
            }
        }
        case 'onImagesSearchChange': {
            const { event: { target: { value } } } = action;
            return {
                ...state,
                userImagesSearchValue: value,
            }
        }
        case 'onPagesSearchChange': {
            const { event: { target: { value } } } = action;
            return {
                ...state,
                pagesSearchValue: value,
            }
        }
        case 'onInputWidgeChange': {
            const { event: { target: { value } } } = action;
            return {
                ...state,
                inputValue: value,
            }
        }
        default:
            return state;
    }
}


export default sitesWidget