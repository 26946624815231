import { combineReducers } from 'redux'

import locationUtil from './location'
import sitesWidget from './sitesWidget'

const user = (state = {}, action) => {
  switch (action.type) {

    default:
      return state;
  }
}

export const rootReducer = combineReducers({

  sitesWidget,
  locationUtil
})