import firebase from '../firebase';

export const addNewSite = () => async (dispatch, getState) => {

    const { sitesWidget: { inputValue } } = getState();

    dispatch({ type: 'addNewSite' })
    var user = await firebase
        .auth()
        .currentUser;
    const nuShayderSitesCollectionRef = firebase.firestore().collection('nuShayderSites')

    await nuShayderSitesCollectionRef.add({
        userId: user.uid,
        name: inputValue,
    })

    dispatch(getUserSites())

    dispatch(onInputWidgeChange({
        target: {
            value: ''
        }
    }))

}

export const onInputWidgeChange = (event) => (dispatch, getState) => {
    dispatch({ type: 'onInputWidgeChange', event })
}

export const onPagesSearchChange = (event) => (dispatch, getState) => {
    dispatch({ type: 'onPagesSearchChange', event })
}

export const getUserSites = () => async (dispatch, getState) => {

    dispatch({ type: 'getUserSites' })

    var user = await firebase
        .auth()
        .currentUser;

    if (user) {
        const sitesRef = firebase.firestore().collection('nuShayderSites');
        const { docs } = await sitesRef.where('userId', '==', user.uid).get();
        const userSites = docs.map(siteDoc => ({
            id: siteDoc.id,
            ...siteDoc.data()
        }));
        dispatch({ type: 'setUserSites', userSites })
    }

}

export const editUserPage = (page) => async (dispatch, getState) => {

    dispatch({ type: 'editUserPage' })

    var user = await firebase
        .auth()
        .currentUser;

    if (user) {
        const pageRef = firebase.firestore().collection('nuShayderSpaces').doc(page.id);
        const userPages = await pageRef.update({ private: page.private || false })
        await dispatch(getUserPages())
    }

}

export const getUserPages = () => async (dispatch, getState) => {

    dispatch({ type: 'getUserPages' })

    var user = await firebase
        .auth()
        .currentUser;

    if (user) {
        const sitesRef = firebase.firestore().collection('nuShayderSpaces');
        const { docs } = await sitesRef.where('userId', '==', user.uid).get();
        const userPages = docs.map(siteDoc => siteDoc.data());
        dispatch({ type: 'setUserPages', userPages })
    }

}

export const deletePage = (pageId) => async (dispatch, getState) => {

    dispatch({ type: 'deletePage' })
    var user = await firebase
        .auth()
        .currentUser;
    const nuShayderSitesCollectionRef = firebase.firestore().collection('nuShayderSpaces')

    const siteData = await nuShayderSitesCollectionRef.doc(pageId);

    await siteData.delete()

    dispatch(getUserPages())
}

export const deleteSite = (siteId) => async (dispatch, getState) => {

    dispatch({ type: 'deleteSite' })
    var user = await firebase
        .auth()
        .currentUser;
    const nuShayderSitesCollectionRef = firebase.firestore().collection('nuShayderSites')

    const siteData = await nuShayderSitesCollectionRef.doc(siteId);

    await siteData.delete()

    dispatch(getUserSites())
}

