import React from 'react';
import { connect } from 'react-redux';
import { addNewSite } from '../../../actions/sitesWidgets'

const addSiteButton = (props) => {
  return <button onClick={props.addNewSite} className='btn'>add new site</button>;
};
export const AddSiteButton = connect(undefined, {
  addNewSite
})(addSiteButton);
