import React from 'react';
import { connect } from 'react-redux';
import { onPagesSearchChange } from '../../../actions/sitesWidgets'

const userPagesInput = (props) => {
  return <input value={props.sitesWidget.pagesSearchValue}  placeholder="search pages" onChange={props.onPagesSearchChange} className='form-control input-lg'/>;
};


const mapStateToProps = ({sitesWidget}, ownProps) => ({
  sitesWidget,
})

export const UserPagesInput = connect(mapStateToProps, {
  onPagesSearchChange
})(userPagesInput);
