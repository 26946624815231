import React, { Component } from 'react';
import { connect } from 'react-redux'

import ShayderPac from 'shayder-pac';

import firebase from '../../firebase';

class Help extends Component {
  constructor() {
    super();
    this.state = {

    }
  }

  componentDidMount() { }

  render() {

    var { history } = this.props

    return (
          <ShayderPac {...{
            firebase,
            shayderMode: {
               pageId: '1fa8a40b-44b7-4e92-a652-c694756a9511',
              type: 'live',
              setLocal:false,
            }
          }} />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  locationUtil: state.locationUtil
})

export default connect(mapStateToProps, {

})(Help)