import React, { Component } from 'react';
import { render } from 'react-dom';
import { connect } from 'react-redux'

import { Route } from 'react-router-dom'

import Home from '../Home';
import Preview from '../Preview';
import Intro from '../Intro';
import Edit from '../Edit';
import Sites from '../Sites';
import ShayderPages from '../ShayderPages';
import ShayderProductPage from '../ShayderProductPage';

import ShayderImages from '../ShayderImages';
import Help from '../Help';

import ShopSettings from '../ShopSettings';

import { HashRouter } from 'react-router-dom'

import rp from 'request-promise';

class App extends Component {
  constructor() {
    super();
    this.state = {

    }
  }

  async componentDidMount() {
    const { hash } = window.location;
    if (hash.indexOf('access_token') > -1) {
      const list = hash.replace('#/', '').split('&');
      const dict = list.filter(sec => sec.indexOf('='))
        .map(sec => sec.split('='))
        .reduce((dict, [key, value]) => ({ ...dict, [key]: value }), {});
      const { access_token } = dict;
      console.log("TCL: App -> componentDidMount -> dict", dict)

      try {
        var options = {
          uri: 'https://us-central1-shayder-space.cloudfunctions.net/shayder-access',
          qs: {
            access_token,
          },
          headers: {
          },
        };

        const response = await rp(options)
        console.log("TCL: App -> componentDidMount -> response", response)
      } catch (error) {

      }
    }
  }

  render() {
    var { history } = this.props
    return (
      <HashRouter history={history} basename='/'>
        <div className="h-100">
          <Route exact path="/" component={Intro} />
          <Route exact path="/help" component={Help} />
          <Route exact path="/shop-settings" component={ShopSettings} />
          <Route exact path="/preview" component={Preview} />
          <Route exact path="/intro" component={Intro} />
          <Route exact path="/edit" component={Edit} />
          <Route exact path="/sites" component={Sites} />
          <Route exact path="/pages" component={ShayderPages} />
          <Route exact path="/images" component={ShayderImages} />
          <Route exact path="/shayder-product-page" component={ShayderProductPage} />
        </div>
      </HashRouter>
    )

  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  locationUtil: state.locationUtil
})

export default connect(mapStateToProps, {

})(App)