import React, { Component } from 'react';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import firebase from '../../firebase';
import rp from 'request-promise';

//const shayderUrlStem = 'http://localhost:3000/#';

const shayderStripAuthUrl = "https://us-central1-shayder-space.cloudfunctions.net/shayderStripeAuth/";
const stripePublicKey = 'ca_FToj7pi1R9y7xzYgU4zcQEf8k3Gx4pjO';
const productLinkStem = `/#/shayder-product-page`;

class ShopSettings extends Component {
    constructor() {
        super();
        this.state = {
            products: [],
        };
    }

    async componentDidMount() {

        const user = await firebase
            .auth()
            .onAuthStateChanged(async (user) => {
                const { hash } = window.location;
                if (hash.indexOf('code') > -1) {
                    const query = hash.split('?')[1]

                    const list = query
                        .replace('#/', '')
                        .split('&');
                    const dict = list
                        .filter(sec => sec.indexOf('='))
                        .map(sec => sec.split('='))
                        .reduce((dict, [key, value]) => ({
                            ...dict,
                            [key]: value
                        }), {});
                    const { code } = dict;

                    try {

                        var options = {
                            method: 'POST',
                            uri: shayderStripAuthUrl,
                            body: JSON.stringify({ code, uid: user.uid }),
                            headers: {
                                "content-type": 'application/json'
                            }
                        };

                        const response = await rp(options);
                        window.history.replaceState({}, document.title, "/#/shop-settings/");

                        const { products } = JSON.parse(response);

                        if (products) {
                            this.setState({ uid: user.uid, products });
                        }

                    } catch (error) {
                        console.log("TCL: componentDidMount -> error", error)
                    }

                } else {
                    this.getProducts(user.uid)
                }

            });
    }
    async getProducts(uid) {

        var options = {
            method: 'Get',
            uri: shayderStripAuthUrl + "products/" + uid,
            headers: {
                "content-type": 'application/json'
            }
        };

        try {
            const response = await rp(options);
            const products = JSON.parse(response);
            this.setState({ products, uid });
        } catch (error) {
            console.log("TCL: getProducts -> error", error);
        }
    }
    onConnectPress = () => {

        const authUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripePublicKey}&scope=read_write`;
        window.location.href = authUrl;
    }
    render() {

        var { history } = this.props;

        return (
            <div className='h-100 d-flex flex-column'>
                <div className='d-flex align-items-center'>
                    <span className='m-3'>
                        shop settings
                    </span>
                    <button onClick={this.onConnectPress} className='btn btn-info'>
                        connect
                    </button>
                </div>
                <div
                    className='flex-1 d-flex flex-column'>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Products</a>
                        </li>

                    </ul>
                    <div className="tab-content flex-1" id="myTabContent">
                        <div className="tab-pane h-100 fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                            <div
                                style={{
                                    backgroundColor: 'green'
                                }}
                                className='h-100 flex-wrap scroll-y'>

                                {this.state.products.map(item => {
                                    const link = `${productLinkStem}?userId=${this.state.uid}&itemId=${item.id}`
                                    const { name, images = [], price } = item;
                                    return (
                                        <div key={item.id} className="card w-25 m-3 p-2">
                                            <p className="">{name}</p>
                                            <p className="">
                                                <CurrencyFormat value={price * .01} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </p>
                                            <img style={{ height: 200 }} src={images[0]} />
                                            <CopyToClipboard text={link} onCopy={() => { }}>
                                                <a href={link} style={{ color: 'blue' }}> product info </a>
                                            </CopyToClipboard>
                                        </div>
                                    )
                                })}

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ user: state.user, locationUtil: state.locationUtil })

export default connect(mapStateToProps, {})(ShopSettings)