
import firebase from '../firebase';

export const onImagesSearchChange = (event) => (dispatch, getState) => {
    dispatch({ type: 'onImagesSearchChange', event })
}

export const getUserImages = () => async (dispatch, getState) => {

    dispatch({ type: 'getUserImages' })

    var user = await firebase
        .auth()
        .currentUser;

    if (user) {
        const imagesRef = firebase.firestore().collection('nuShayderUserImages');
        const { docs } = await imagesRef.where('userId', '==', user.uid).get();
        const userImages = docs.map(imageMetaDoc => ({
            id: imageMetaDoc.id,
            ...imageMetaDoc.data()
        }));
        dispatch({ type: 'setUserImages', userImages })
    }

}