import React, { Component } from 'react';
import { connect } from 'react-redux'

import ShayderPac from 'shayder-pac';

import firebase from '../../firebase';

import shayderSignUpComponentKeys from './SignUpWidget/shayderSignUpComponentKeys'

class Intro extends Component {
  constructor() {
    super();
    this.state = {

    }
  }

  componentDidMount() { }

  render() {

    var { history } = this.props

    return (
      <ShayderPac {...{
        firebase,
        shayderMode: {
          pageId: 'cc9143b8-1342-4e65-9787-b3ab7baccc44',
          type: 'live',
          setLocal: false,
          components: {

          }
        }
      }} />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  locationUtil: state.locationUtil
})

export default connect(mapStateToProps, {

})(Intro)