import React, { Component } from 'react';
import { connect } from 'react-redux'
import firebase from '../../firebase'
import { getUserImages } from '../../actions/userImages'

class UserImages extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    onDeleteImage = async (imageData) => {

        var storageRef = firebase
            .storage()
            .ref();

        var fileRef = storageRef
            .child(imageData.childName)

        await fileRef.delete()
        const nuShayderUserImagesRef = firebase.firestore().collection('nuShayderUserImages');
        const imageDataRef = nuShayderUserImagesRef.doc(imageData.id)
        await imageDataRef.delete()
        console.log("TCL: UserImages -> onDeleteImage -> fileRef", fileRef)
        this.props.getUserImages()

    }

    componentDidMount() {
        setTimeout(() => {
            this.props.getUserImages()
        }, 1000)
    }

    render() {
        return this.props.sitesWidget.userImages.map((imageData, i) => {
            return (
                <div
                    className="card p-3 m-3" key={i}>
                    <p>{imageData.fileName}</p>
                    <img style={{ height: 200, width: 200 }} src={imageData.downloadURL} />
                    <div>
                        <button className='m-2 btn'>edit</button>
                        <button onClick={() => {
                            this.onDeleteImage(imageData)
                        }} className='m-2 btn btn-warning'>delete</button>
                    </div>
                </div>
            )
        })
    }
}

const mapStateToProps = ({ sitesWidget }, ownProps) => ({
    sitesWidget
})

export default connect(mapStateToProps, {
    getUserImages
})(UserImages)


