import React, { Component } from 'react';

import ShayderSignUp from './index';

const shayderSignUpComponentKeys = {
    'd29b685e-95c7-4290-baab-41da990610d8': {
        domId: "d29b685e-95c7-4290-baab-41da990610d8",
        name: 'ShayderSignUp',
        node: <ShayderSignUp />,
    },
}

export default shayderSignUpComponentKeys;