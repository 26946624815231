import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

const SuccessSnackBar = (props) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    open={props.open}
    autoHideDuration={3000}
    onClose={props.handleClose}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{props.message}</span>}

  />)

export default SuccessSnackBar;
