import React, { Component } from 'react';
import { connect } from 'react-redux'

import ShayderPac from 'shayder-pac';
import  shayderImagesComponentKeys  from '../ShayderImages/shayderImagesComponentKeys'

import firebase from '../../firebase';
class Edit extends Component {
  constructor() {
    super();
    this.state = {

    }
  }
  onHostSiteSelect = () => {

    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?
    scope=https://www.googleapis.com/auth/cloud-platform&
    include_granted_scopes=true&
    state=state_parameter_passthrough_value&
    response_type=token&
    redirect_uri=https://shayder.space&
    client_id=538905616309-t9ve2ct7b3dq9k6n9abirdoacc85psk1.apps.googleusercontent.com`
  }
  componentDidMount() { }

  render() {

    var { history } = this.props

    return (
      <ShayderPac {...{
        firebase,
        deployMode: {
          type: 'firebase',
          title:'host on firebase',
          onHostSiteSelect: this.onHostSiteSelect
        },
        shayderMode: {
          type: 'edit',
          components:{
           
          }
        },
 
      }} />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  locationUtil: state.locationUtil
})

export default connect(mapStateToProps, {

})(Edit)