import  UserPages  from '../Sites/SitesListWidget/userPages'
import React, { Component } from 'react';

import  {UserPagesInput} from '../Sites/SitesListWidget/userPagesInput'


const shayderPagesComponentKeys = {
    '97557513-8e7d-4f66-9eba-6aab24d12cf3': {
        domId: "97557513-8e7d-4f66-9eba-6aab24d12cf3",
        name: 'user pages',
        node: <UserPages />,
    },
    'a71b98fa-98f2-46de-a1f7-5303cb2cbcca': {
        domId: "a71b98fa-98f2-46de-a1f7-5303cb2cbcca",
        name: 'UserPagesInput',
        node: <UserPagesInput />,
    }
}

export default shayderPagesComponentKeys;