import firebase from "firebase";

// See firebase setup in above google firebase documentation url
export const config = {
    apiKey: "AIzaSyCi3vybN7QiB5AbJlcFDMRDm8T_Ioj-YKI",
    authDomain: "shayder-space.firebaseapp.com",
    databaseURL: "https://shayder-space.firebaseio.com",
    projectId: "shayder-space",
    storageBucket: "shayder-space.appspot.com",
    messagingSenderId: "538905616309"
};

firebase.initializeApp(config);
const firestore = firebase.firestore();
export default firebase;