import React from 'react';
import { connect } from 'react-redux';
import { onInputWidgeChange } from '../../../actions/sitesWidgets'

const siteWidgetInput = (props) => {
  return <input value={props.sitesWidget.inputValue}  placeholder="search / new site name" onChange={props.onInputWidgeChange} className='form-control input-lg'/>;
};


const mapStateToProps = ({sitesWidget}, ownProps) => ({
  sitesWidget,
})

export const SiteWidgetInput = connect(mapStateToProps, {
  onInputWidgeChange
})(siteWidgetInput);
