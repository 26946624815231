import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import ShayderPac from 'shayder-pac';

import firebase from '../../firebase';

import shayderImagesComponentKeys from './shayderImagesComponentKeys'

class ShayderImages extends Component {
  constructor() {
    super();
    this.state = {

    }
  }

  componentDidMount() {

    setTimeout(() => {
      this.setState({ showTime: true })
    }, 2000)
  }


  render() {

    return (
      <React.Fragment>
        <ShayderPac {...{
          firebase,
          shayderMode: {
            pageId: '0c3d1889-8847-419f-b63d-91a43f934373',
            type: 'live',
            setLocal: false,
            components: {
              ...shayderImagesComponentKeys
            }
          }
        }} />

      </React.Fragment >

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  locationUtil: state.locationUtil
})

export default connect(mapStateToProps, {

})(ShayderImages)