import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import ShayderPac from 'shayder-pac';

import firebase from '../../firebase';

import shayderPagesComponentKeys from './shayderPagesComponentKeys';

class ShayderPages extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.setState({ showTime: true });
    }, 2000);
  }
  render() {
    return (
      <React.Fragment>
        <ShayderPac {...{
          firebase,
          shayderMode: {
            pageId: 'cdd296a7-b5d0-49fd-9a75-5a32feb3265e',
            type: 'live',
            setLocal: false,
            components: {
              ...shayderPagesComponentKeys,
            }
          }
        }} />
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  locationUtil: state.locationUtil
})

export default connect(mapStateToProps, {

})(ShayderPages)