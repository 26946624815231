import React, { Component } from 'react';
import { connect } from 'react-redux'
import Switch from "react-switch";

import { getUserPages, editUserPage, deletePage } from '../../../actions/sitesWidgets'

class UserPages extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.getUserPages()
        }, 1000)
    }
    onEditClick = (pageData) => {

        const { id, name, gridCells } = pageData

        localStorage.setItem('nu.shayder.way', JSON.stringify({ id, name, gridCells }));

        window.location.href = "/#/edit"

    }

    onDeleteClick = (pageData) => {
        if (window.confirm(` Are you sure you want to delete ${pageData.name}`)) {
            this.props.deletePage(pageData.id)
        }
    }

    editPage(pageData) {

        this.props.editUserPage(pageData)

    }

    render() {
        return this.props.sitesWidget.userPages.map((page, i) => {
            return (
                <div
                    key={i}
                    onDragStart={(event) => {
                        event.dataTransfer.setData("pageId", page.id);
                    }}
                    draggable className="card p-3 m-3" key={i}>
                    <p>{page.name}</p>
                    <div  >
                        {
                            !page.private &&
                            <a className='btn btn-link' href={`https://pages.shayder.space/${page.userId}/${page.id}`} target="_blank"> page link</a>
                        }
                        <button onClick={() => this.onDeleteClick(page)} className='m-2 btn btn-warning'>delete</button>
                        <br />
                        <div className='d-flex align-items-center justify-content-around' >
                        <span>Private?</span>
                        <Switch
                            onChange={(event) => {
                                this.editPage({ private: event, id: page.id })
                            }}
                            checked={page.private || false} />
                            </div>
                    </div>
                </div>
            )
        })
    }
}

const mapStateToProps = ({ sitesWidget }, ownProps) => ({
    sitesWidget
})

export default connect(mapStateToProps, {
    getUserPages,
    deletePage,
    editUserPage,
})(UserPages)


