import React from 'react';
import { connect } from 'react-redux';
import { onImagesSearchChange } from '../../actions/userImages'

const userImagesInput = (props) => {
  return <input value={props.sitesWidget.userImagesSearchValue}  placeholder="search Images" onChange={props.onImagesSearchChange} className='form-control input-lg'/>;
};


const mapStateToProps = ({sitesWidget}, ownProps) => ({
  sitesWidget,
})

export const UserPagesInput = connect(mapStateToProps, {
  onImagesSearchChange
})(userImagesInput);
