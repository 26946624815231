import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import ShayderPac from 'shayder-pac';

import firebase from '../../firebase';
import  sitesWidgetShayderComponentKeys  from './SitesListWidget/shayderComponentKeys';



class Sites extends Component {
  constructor() {
    super();
    this.state = {

    }
  }

  componentDidMount() {

    setTimeout(() => {
      this.setState({ showTime: true })
    }, 2000)
  }


  render() {

    var { history } = this.props

    return (
      <React.Fragment>
        <ShayderPac {...{
          firebase,
          shayderMode: {
            pageId: 'ae3a76f6-b640-4017-8bde-5fb3f1ef6d7a',
            type: 'live',
            setLocal: false,
            components: {
              ...sitesWidgetShayderComponentKeys
            }
          }
        }} />

      </React.Fragment >

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  locationUtil: state.locationUtil
})

export default connect(mapStateToProps, {

})(Sites)