import {createStore, applyMiddleware, compose, combineReducers} from 'redux'
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'
import {rootReducer} from '../reducers/'
import initState from './initState'

const logger = createLogger();

const configureStore = preloadedState => {
  const store = createStore(rootReducer, preloadedState, compose(applyMiddleware(thunk)))
  return store
}

let preloadedState = initState();

const waystring = localStorage.getItem('nu.shayder.way');

if (waystring) {
  const {nodeItems, gridCells} = JSON.parse(waystring);
  preloadedState = {
   
  }
}

export const store = configureStore(preloadedState);